<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="dialog = true">
                    Agregar Nuevo Usuario
                </v-btn>
            </v-col>
            <v-col cols="12">
                <v-data-table :headers="headers" :items="notificacionesDatos" :loading="saving" class="elevation-1">
                    <template v-slot:item.actions="{ item }">
                        <!-- <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                        </v-icon> -->
                        <v-icon small @click="deleteItem(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                    <template v-slot:no-data>
                        <v-btn color="primary" @click="initialize">
                            Reset
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-autocomplete v-model="editedItem.idUsuario" label="Nombre"
                                    :items="usuariosNoAsignados" item-value="idUsuario" :item-text="nombreUsuarios"
                                    :disabled="saving" :loading="saving"></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close" :disabled="saving" :loading="saving">
                        Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save" :disabled="saving" :loading="saving">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete" :disabled="saving"
                        :loading="saving">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm" :disabled="saving"
                        :loading="saving">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data: () => ({
        dialog: false,
        dialogDelete: false,
        headers: [
            {
                text: 'Nombre', align: 'center', value: 'nombre',
                sortable: false,
            },
            {
                text: 'Proveedor Notificacion', value: 'tipo', align: 'center',
                sortable: false,
            },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        notificacionesDatos: [],
        usuariosNoAsignados: [],
        editedIndex: -1,
        editedItem: {
            idNotificacionUsuario: null,
            idUsuario: null,
            nombre: null,
            tipo: null
        },
        defaultItem: {
            idNotificacionUsuario: null,
            idUsuario: null,
            nombre: null,
            tipo: null
        },
        saving: false
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Asignar Usuario' : 'Edit Item'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.initialize()
    },

    methods: {
        initialize() {
            this.getNotificaciones();
            this.getUsuariosNoAsignados();
        },
        getNotificaciones() {
            axios
                .get("/NotificacionesUsuarios")
                .then(response => {
                    this.notificacionesDatos = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getUsuariosNoAsignados() {
            axios
                .get("/NotificacionesUsuarios/UsuariosNoAsignados")
                .then(response => {
                    this.usuariosNoAsignados = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        nombreUsuarios: (value) => `[${value.tipo}] - ${value.nombre}`,
        editItem(item) {
            this.editedIndex = this.notificacionesDatos.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.notificacionesDatos.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            axios
                .put(`/NotificacionesUsuarios/Eliminar/${this.editedItem.idNotificacionUsuario}/${this.editedItem.idUsuario}`)
                .then(() => {
                    this.saving = false;
                    this.initialize();
                })
                .catch((error) => {
                    Error.log(error);
                });
            this.closeDelete();
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            this.saving = true;
            if (this.editedIndex > -1) {
                Object.assign(this.notificacionesDatos[this.editedIndex], this.editedItem)
            } else {
                //this.notificacionesDatos.push(this.editedItem)
                axios
                    .post(`/NotificacionesUsuarios/Crear/${this.editedItem.idUsuario}`)
                    .then(response => {
                        this.saving = false;
                        this.close();
                        this.initialize();
                    })
                    .catch(error => {
                        this.saving = false;
                        console.error(error);
                    });
            }
            this.close()
        },
    },
}
</script>